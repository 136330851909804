<template>
    <div class="practical-container">
        <div class="practical-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-if="role === 3">
                    <span class="color-blue" @click="goBackTwo">班级管理</span>
                </el-breadcrumb-item>
                <!--<el-breadcrumb-item v-if="role === 4">-->
                    <!--<span class="color-blue" @click="goBack">我的班级</span>-->
                <!--</el-breadcrumb-item>-->
                <el-breadcrumb-item v-if="role === 3">
                    <span class="color-blue" @click="goBack">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>实训列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="practical-list">
                <div class="student-header">
                    <div class="header-left">
                        <div class="avatar">
                            <img v-if="s_ico" :src="s_ico" alt="">
                            <img v-else src="../../assets/images/user_img.png" alt="" />
                        </div>
                        <span class="student-name">{{s_name}}</span>
                    </div>
                    <div class="header-right" @click="clickSwitch">
                        <i class="iconfont">&#xec8d;</i>
                        <span class="text" v-html="is_switch ? '实训' : '小测'"></span>
                    </div>
                </div>
                <div class="select course-list">
                    <span class="select-text">课程筛选：</span>
                    <el-select v-model="courseValve" placeholder="请选择" style="width: 150px" clearable @change="selectCourse">
                        <el-option
                                v-for="item in courseList"
                                :key="item.course_id"
                                :label="item.course_name"
                                :value="item.course_id">
                        </el-option>
                    </el-select>
                </div>
                <!--分数图表-->
                <div class="trend-content" id="scoreECharts"></div>
                <div class="table-item">
                    <span class="item-text">得分详情</span>
                    <el-table
                            v-show="is_switch"
                            :data="xcScoreList"
                            border
                            style="width: 100%; flex: 1;"
                            height="1%"
                            size="medium"
                            :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F5F5F5', textAlign: 'center'}"
                            :cell-style="{fontSize: '12px',color: '#666', textAlign: 'center'}">
                        <el-table-column width="120">
                            <template slot-scope="scope">
                                <span>第{{scope.row.rank}}次</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="quiz_name" label="小测名称"></el-table-column>
                        <el-table-column prop="score" label="小测分数" width="200"></el-table-column>
                        <el-table-column prop="classAvgScore" label="班级平均分" width="200"></el-table-column>
                        <el-table-column label="操作" width="120" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="primary" @click="xiaoceDetail(scope.row)">查看详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            v-show="!is_switch"
                            :data="sxScoreList"
                            border
                            style="width: 100%; flex: 1;"
                            height="1%"
                            size="medium"
                            :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F5F5F5', textAlign: 'center'}"
                            :cell-style="{fontSize: '12px',color: '#666', textAlign: 'center'}">
                        <el-table-column width="120">
                            <template slot-scope="scope">
                                <span>第{{scope.row.rank}}次</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sx_name" label="实训名称"></el-table-column>
                        <el-table-column prop="score" label="实训分数" width="200">
                            <template slot-scope="scope">
                                <span v-if="scope.row.score">{{scope.row.score}}</span>
                                <span v-else>暂未评分</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="classAvgScore" label="班级平均分" width="200"></el-table-column>
                        <el-table-column label="操作" width="120" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="primary" @click="practicalDetail(scope.row)">查看详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pages-center" background
                                   :current-page.sync="scorePages.currentPageNum"
                                   :page-size="scorePages.eachPageNum"
                                   :total="scorePages.total"
                                   layout="prev, pager, next, jumper"
                                   @current-change="scoreCurrentChange">
                    </el-pagination>
                </div>
                <div class="table-item">
                    <span class="item-text">考勤情况</span>
                    <el-table
                            :data="signInData"
                            style="width: 100%; flex: 1;"
                            border
                            height="1%"
                            :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F5F5F5', textAlign: 'center'}"
                            :cell-style="{fontSize: '12px',color: '#666', textAlign: 'center'}">
                        <el-table-column prop="start_time" label="日期"></el-table-column>
                        <el-table-column prop="course_name" label="课程"></el-table-column>
                        <el-table-column prop="node" label="章节">
                            <template slot-scope="scope">
                                <span>第{{scope.row.chapter_sign + 1}}章</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sign_status" label="签到状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sign_status === 1">已签到</span>
                                <span v-else class="noSignIn">未签到</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="t_name" label="授课老师"></el-table-column>
                    </el-table>
                    <el-pagination class="pages-center" background
                                   :current-page.sync="signInPages.currentPageNum"
                                   :page-size="signInPages.eachPageNum"
                                   :total="signInPages.total"
                                   layout="prev, pager, next, jumper"
                                   @current-change="signInCurrentChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StudentDetail",
        data() {
            return {
                //班级名称，课程id，角色id
                className: '',
                classId: '',
                studentId: Number(this.$route.query.sId) || '',
                s_ico: '',
                s_name: '',
                role: Number(localStorage.getItem('role')) || '',
                //课程选择
                courseValve: '',
                courseList: [],
                //分页
                scorePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                signInPages: {
                    currentPageNum: 1,
                    eachPageNum: 5,
                    total: 0,
                },
                //分数图表
                scoreECharts: null,
                scoreData:[],
                classAvgScoreData:[],
                //小测分数列表
                xcScoreList: [],
                //实训分数列表
                sxScoreList: [],
                //考勤列表
                signInData: [],
                //点击切换
                is_switch:true,
                //得分详情没有数据提示
                scoreNoPrompt: null,
                //次数
                numData: []
            }
        },
        created() {
            this.getStudentInfo();
        },
        mounted() {
            this.scoreECharts = this.$echarts.init(document.getElementById('scoreECharts'));
            this.getScoreECharts();
            let resizeTimer = null;
            let _this = this;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    _this.getScoreECharts.resize()
                }, 100)
            }
        },
        methods: {
            //获取单个学生信息
            getStudentInfo() {
              this.$shttp.axiosGetBy(this.$api.jd_getStuInfo, {s_id: this.studentId}, (res) => {
                  if (res.code === 200) {
                      this.classId = res.data.c_id;
                      this.className = res.data.c_name;
                      this.s_ico = res.data.s_ico.src;
                      this.s_name = res.data.s_name;
                      this.getCourses();
                  } else {
                      this.$message.error(res.msg);
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            //点击切换
            clickSwitch() {
                this.is_switch = !this.is_switch;
                if (this.is_switch) {
                    this.getXcScoreList();
                } else {
                    this.getSxScoreList();
                }
                this.scoreData = [];
                this.numData = [];
                this.classAvgScoreData = [];
            },
            //筛选
            selectCourse(val) {
                this.courseValve = val;
                this.getSignInList();
                this.getScoreECharts();
                if (this.is_switch) {
                    this.getXcScoreList();
                } else {
                    this.getSxScoreList();
                }
                this.scoreData = [];
                this.numData = [];
                this.classAvgScoreData = [];
            },
            //课程列表
            getCourses() {
                if (this.role === 4) {
                    let params = {
                        parent_id: 'no',
                        type: 1,
                        sort: 1
                    }
                    this.$shttp.axiosGetBy(this.$api.admin_courseLists, params, (res) => {
                        if (res.code === 200) {
                            this.courseList = res.list;
                            this.courseValve = res.list[0].course_id;
                            if (this.courseValve) {
                                this.getSignInList();
                                if (this.is_switch) {
                                    this.getXcScoreList();
                                } else {
                                    this.getSxScoreList();
                                }
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    });
                } else if (this.role === 3) {
                    this.$shttp.axiosGet(this.$api.admin_showTeaCourse, (res) => {
                        if (res.code === 200) {
                            this.courseList = res.data.course_list;
                            this.courseValve = res.data.course_list[0].course_id;
                            if (this.courseValve) {
                                this.getSignInList();
                                if (this.is_switch) {
                                    this.getXcScoreList();
                                } else {
                                    this.getSxScoreList();
                                }
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //分数图表
            getScoreECharts() {
                this.scoreECharts.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#A3ABB4',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                    },
                    legend: {
                        data:['学生成绩','班级平均分'],
                        icon: 'circle',
                        orient: 'horizontal',
                        x:'right',
                        y:'top',
                        padding: [0, 10, 20, 0],
                        textStyle: {
                            color: '#A3ABB4', // 字体颜色
                            fontWeight: 'normal',
                            fontSize: 14
                        },
                        selectedMode: false
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: this.numData,
                            axisLabel: {
                                textStyle: {
                                    color: '#A3ABB4'
                                },
                                align: 'center'
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#F3F6FA'
                                }
                            },
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                show: true,
                                textStyle: {
                                    color: '#999'
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#F3F6FA'
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    color: '#F3F6FA'
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '班级平均分',
                            type: 'line',
                            label: {
                                normal: {
                                    show: false,
                                    position: 'top'
                                }
                            },
                            symbol: 'circle',
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#E43B3E",
                                    borderColor: '#fff',
                                    borderWidth: 2,
                                }
                            },
                            areaStyle: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgba(255, 255, 255, 0)'
                                    },
                                    {
                                        offset: 0.5,
                                        color: '#ff8c8ecf'
                                    },
                                    {
                                        offset: 1,
                                        color: '#ff8c8e'
                                    }
                                ], false)
                            },
                            data: this.classAvgScoreData
                        },
                        {
                            name: '学生成绩',
                            type: 'line',
                            label: {
                                normal: {
                                    show: false,
                                    position: 'top'
                                }
                            },
                            symbol: 'circle',
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#1E88E5",
                                    borderColor: '#fff',
                                    borderWidth: 2,
                                }
                            },
                            areaStyle: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgba(255, 255, 255, 0)'
                                    },
                                    {
                                        offset: 0.5,
                                        color: 'rgba(30, 136, 229, 0.3)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(30, 136, 229, 0.5)'
                                    }
                                ], false)
                            },
                            data: this.scoreData
                        }
                    ]
                }, true);
            },
            //小测分数列表
            getXcScoreList() {
              let param = {
                  s_id: String(this.studentId),
                  // course_id: String(this.courseValve),
                  page: this.scorePages.currentPageNum,
                  limit: this.scorePages.eachPageNum
              }
              if (this.courseValve) {
                  param.course_id = String(this.courseValve);
              }
              this.$shttp.axiosGetBy(this.$api.jd_getStuQuizDetails, param, (res) => {
                  if (res.code === 200) {
                      this.xcScoreList = res.data.data;
                      if (this.xcScoreList.length > 0) {
                          let num = '';
                          this.xcScoreList.forEach(item => {
                              if (item.score === null) {
                                  item.score = 0;
                              }
                              if (item.rank) {
                                  num = `第${item.rank}次`
                              }
                              this.scoreData.push(item.score);
                              this.numData.push(num);
                              this.classAvgScoreData.push(item.classAvgScore);
                          })
                          if (this.scoreNoPrompt) {
                              document.querySelector("#scoreECharts").removeChild(this.scoreNoPrompt);
                              this.scoreNoPrompt = null;//移除掉'暂无数据'后还要把他置为null，没有数据的时候才会再加上'暂无数据'
                          }
                      }
                      this.getScoreECharts();
                  } else if (res.code === 400) {
                      this.getScoreECharts();
                      this.scoreData = [];
                      this.numData = [];
                      this.classAvgScoreData = [];
                      this.xcScoreList = [];
                      // //得分详情没有数据提示
                      if (!this.scoreNoPrompt) {
                          this.scoreNoPrompt = document.createElement("div");
                          this.scoreNoPrompt.className='no-data';
                          this.scoreNoPrompt.innerHTML = '暂无数据';
                          this.scoreNoPrompt.style.color = "#666";
                          this.scoreNoPrompt.style.position = "absolute";
                          this.scoreNoPrompt.style.top = "100px";
                          this.scoreNoPrompt.style.left = "50%";
                          document.querySelector("#scoreECharts").appendChild(this.scoreNoPrompt);
                      }
                  } else {
                      this.$message.error(res.msg);
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            //实训分数列表
            getSxScoreList() {
              let param = {
                  s_id: String(this.studentId),
                  // course_id: String(this.courseValve),
                  page: this.scorePages.currentPageNum,
                  limit: this.scorePages.eachPageNum
              }
                if (this.courseValve) {
                    param.course_id = String(this.courseValve);
                }
              this.$shttp.axiosGetBy(this.$api.jd_getStuSxDetails, param, (res) => {
                  if (res.code === 200) {
                      this.sxScoreList = res.data.data;
                      if (this.sxScoreList.length > 0) {
                          let num = '';
                          this.sxScoreList.forEach(item => {
                              if (item.score === null) {
                                  item.score = 0;
                              }
                              if (item.rank) {
                                  num = `第${item.rank}次`
                              }
                              this.scoreData.push(item.score);
                              this.numData.push(num);
                              this.classAvgScoreData.push(item.classAvgScore);
                          })
                          if (this.scoreNoPrompt) {
                              document.querySelector("#scoreECharts").removeChild(this.scoreNoPrompt);
                              this.scoreNoPrompt = null;//移除掉'暂无数据'后还要把他置为null，没有数据的时候才会再加上'暂无数据'
                          }
                      }
                      this.getScoreECharts();
                  } else if (res.code === 400) {
                      this.getScoreECharts();
                      this.scoreData = [];
                      this.numData = [];
                      this.classAvgScoreData = [];
                      this.sxScoreList = [];
                      // //得分详情没有数据提示
                      if (!this.scoreNoPrompt) {
                          this.scoreNoPrompt = document.createElement("div");
                          this.scoreNoPrompt.className='no-data';
                          this.scoreNoPrompt.innerHTML = '暂无数据';
                          this.scoreNoPrompt.style.color = "#666";
                          this.scoreNoPrompt.style.position = "absolute";
                          this.scoreNoPrompt.style.top = "100px";
                          this.scoreNoPrompt.style.left = "50%";
                          document.querySelector("#scoreECharts").appendChild(this.scoreNoPrompt);
                      }
                  } else {
                      this.$message.error(res.msg);
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            //查看实训详情
            practicalDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: '/teacherClass/practicalDetail',
                    query: {
                        p_id: item.project_id,
                        sx_id: item.id,
                        className: this.className
                    }
                })
                window.open(routeUrl .href, '_blank');
            },
            //查看实训详情
            xiaoceDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: '/teacherClass/allApprovedDetail',
                    query: {
                        quizId: item.id,
                        studentId: this.studentId,
                        classId: this.classId,
                        className: this.className,
                        paperName: item.quiz_name,
                        studentName: this.s_name,
                    }
                })
                window.open(routeUrl .href, '_blank')
            },
            //考勤列表
            getSignInList() {
                let params = {
                    c_id: String(this.classId),
                    s_id: String(this.studentId),
                    course_id: String(this.courseValve),
                    page: this.signInPages.currentPageNum,
                    limit: this.signInPages.eachPageNum
                }
                this.$shttp.axiosGetBy(this.$api.jd_getStuSignInDetails, params, (res) => {
                    if (res.code === 200) {
                        this.signInData = res.data.data;
                        this.signInPages.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            scoreCurrentChange(val) {
                this.scorePages.currentPageNum = val;
                this.getPracticalList();
            },
            signInCurrentChange(val) {
                this.signInPages.currentPageNum = val;
                this.getSignInList();
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
            goBackFour() {
                this.$router.go(-4)
            }
        }
    }
</script>

<style scoped lang="scss">
    .practical-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .practical-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
        }
    }
    ::v-deep .el-breadcrumb {
        margin-bottom: 20px;
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .practical-list {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #fff;
    }
    .course-list {
        margin: 20px;
    }
    .pages-center {
        margin-bottom: 20px;
    }
    .student-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
        margin: 0 20px;
        border-bottom: 1px solid #EEEEEE;
        .header-left {
            display: flex;
            justify-content: start;
            align-items: center;
            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 50%;
                }
            }
            .student-name {
                font-size: 24px;
            }
        }
        .header-right {
            font-size: 18px;
            color: #409EFF;
            cursor: pointer;
            i {
                margin-right: 10px;
            }
        }
    }
    .trend-content {
        position: relative;
        margin: 0 20px;
        height: 300px;
    }
    .table-item {
        display: flex;
        flex-direction: column;
        height: 390px;
        margin: 10px 20px;
        .item-text {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .noSignIn {
            color: #FF0000;
        }
    }
</style>